@import '../styles/config';
@import '../styles/colors';
@import '../styles/mixins';

ul.people {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -1rem;

  li {
    width: 33.3%;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 3rem;
    // justify-content: center;

    $imgWidth: 90%;
    a.box {
      display: flex;
      align-items: center;
      position: relative;
      text-decoration: none;
      padding-top: $imgWidth;
      width: 100%;

      .img {
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        filter: grayscale(100) contrast(1.2);
        width: $imgWidth;
        padding-top: $imgWidth;
        display: block;
        position: absolute;
        top: 0;
        left: (100% - $imgWidth) / 2;
        z-index: 1;
        &:before{
          content: '';
          background: url(../imagetexture.png) $imageOverlay top left no-repeat;
          background-size: cover;
          z-index: 2;
          position: absolute;
          left: 0;
          top: 0;
          width: 100%;
          padding-top: 100%;
        }
      }

      span.name {
        position: absolute;
        top: 0;
        left: (100% - $imgWidth) / 2;
        font-size: 3.5rem;
        font-weight: bold;
        @extend .headline-sans;
        span{
          @extend .highlighted-text;
          color: $popColor;
        }
        line-height: 1.1;
        width: $imgWidth;
        height: 100%;
        z-index: 3;
        padding: 1rem;
        text-align: center;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }

    .descr {
      display: block;
      width: 300px;
      text-decoration: none;
      color: $black;
      font-weight: bold;
      margin-top: 1.2rem;
    }
  }
}
@media (max-width: 1133px){
  ul.people{
    li{
      width: 50%;
    }
  }
}
@media (max-width: $breakpoint-md - 1){
  ul.people{
    li{
      width: 100%;
    }
  }
}

// @media (min-width: $breakpoint-sm) {
//   ul.people{
//     li {
//       a.box{
//         width: 300px;
//       }
//     }
//   }
// }