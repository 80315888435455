

$popColor: #00FFE8;
$popColorAlt: #02D0BD;

$orangeBg: #FF793A;
$orangeDark: #ec5108;
$grayBg: #e7e7e7;
$whiteBg: white;

$black: black;

$whiteText: white;
$blackText: $black;
$blueText: #2c6663;
$formText: #777;
// $imageOverlay: rgba(31, 11, 11, 0.35);
$imageOverlay: transparent;

$inputBg: #eee;
$inputFocusOutline: 1px solid $formText;

$btnDisabledBg: #8AE5E0 ;
$btnDisabledText: #686565;
