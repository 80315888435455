@import './colors';
.a{
  text-decoration: none;
  color: $orangeDark;
  &:hover{
    color: $orangeBg;
    text-decoration: underline;
  }
}
.h1{
  font-size: 4.3rem;
  font-weight: bold;
  color: $popColor;
  @extend .headline-sans;
  line-height: 1.1;
  letter-spacing: 0.3px;
}
.headline-sans{
  font-family: 'Work Sans', sans-serif;
  font-weight: 900;
}
.sans{
  font-family: 'Source Sans Pro', sans-serif;
}
.highlighted-text{
  color: $whiteText; 
  background-color: $blackText;
  padding: 0 0px 5px;
  box-shadow: 10px 0 0 $blackText, -10px 0 0 $blackText;
  box-decoration-break: clone;
}
.dark-nav{
  ul#nav-menu li a {
    color: $blackText;
    &.active,
    &:hover{
      border-bottom-color: $blackText;
    }
  }
}

@mixin sides($margin) {
  margin-left: -$margin;
  margin-right: -$margin;
  padding-right: $margin;
  padding-left: $margin;
}