@import "reset.css";
@import './styles/colors';
@import './styles/mixins';

*{
  box-sizing: border-box;
}
html {
  font-size: 10px; /* set the base val for 1 rem */
}
body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 1.7rem;
  @extend .sans;
  line-height: 1.2;
  background-color: $whiteBg;
}
em{
  font-style: italic;
}

.cap-width {
  max-width: 1100px;
  margin: 0 auto;
}

.btn {
  display: block;
  background-color: $popColor;
  padding: 10px 20px;
  border: none;
  border-radius: 2px;
  &:disabled{
    color: $btnDisabledText;
  }
}

.link-btn {
  background-color: transparent;
  border: none;
  padding: 3px 0;
  line-height: 1.5;
  cursor: pointer;
  text-decoration: none;
  color: #523c31;
  text-align: center;
  display: block;
  width: 100%;
  &:hover{
    text-decoration: underline;
  }
}

h1, h2, h3, h4 {
  margin-bottom: 2rem;
}
h2{
  @extend .headline-sans;
  line-height: 1.2;
}

h3 {
  font-size: 3rem;
}

p {
  margin-bottom: 2rem;
}

strong{
  font-weight: 700;
}
