@import '../styles/config';
@import '../styles/colors';
@import '../styles/mixins';
@import '../styles/hamburgers/hamburgers';

nav {
  display: block;
  margin-bottom: 3rem;
  position: relative;
  transition: 0.1s all ease-in;
  background-color: rgba(0, 0, 0, 0);
  padding: 3px 0 0 0;

  &::after {
    content: "";
    display: table;
    clear: both;
  }

  .brand {
    float: left;
    width: 100%;
    max-width: 80%;
    padding: 12px $pagePad;

    h1 {
      margin: 0;
      font-size: 2rem;
      a{
        // color: $orangeBg;
        text-decoration: none;
        @extend .sans;
        color: transparent;
        display: block;
        background: url(../assets/logo-final.svg) left center no-repeat;
        background-size: auto 100%;
        height: 30px;
        width: 80%;
      }
    }
  }

  .hamburger {
    position: absolute;
    right: 0;
    top: 0;
    outline: none;
  }

  &.open{
    background-color: rgba(0, 0, 0, 1);
  }
  ul#nav-menu {
    max-height: 0px;
    overflow: hidden;
    clear: both;
    background-color: $black; // for now
    transition: 0.2s all ease-out;

    &.open {
      max-height: 200px;

      li a {
        color: $whiteText !important; // case for .App.no-hs
      }
    }

    li {
      list-style: none;
      padding: 1rem 15px;
      flex-shrink: 0;

      a {
        color: $whiteText;
        text-decoration: none;
        text-transform: uppercase;
        font-size: 1.6rem;
        border-bottom: 1px solid transparent;
        padding: 5px 0;

        &.active {
          border-bottom-color: $whiteText;
        }
      }
    }
  }
}


@media (min-width: $breakpoint-menu) {
  body {
    nav {
      display: flex;
      align-items: center;
      padding: 15px 15px 0;

      .brand {
        flex-grow: 0;
        a{
          height: 50px;
        }
      }

      .hamburger {
        display: none;
      }
      
      ul#nav-menu {
        display: flex;
        flex-grow: 1;
        justify-content: flex-end;
        background-color: transparent;
        max-height: none;
        overflow: visible;

        &.open {
          display: flex;
        }
      }
    }
    .page-news,
    .poi-detail,
    .page-persons-of-interest,
    .page-about {
      nav .brand a {
        height: 30px;
      }
    }
  }
}
@media (min-width: $breakpoint-xl) {
  body {
    nav {
      padding-left: 0;
      padding-right: 0;
      .brand{
        padding-left: 0;
        padding-right: 0;
      }
    }
  }

}
