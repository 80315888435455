@import '../styles/config';
@import '../styles/colors';

.news {
  .news-item {
    position: relative;
    margin: 0 0 3rem;
    border-bottom: 1px solid black;
    padding-bottom: 3rem;

    h3 a {
      color: $orangeBg;
      text-decoration: none;
    }

    h4 {
      color: $black;
      font-weight: bold;
    }

    .news-body {
      margin-top: 2rem;
      font-size: 1.6rem;
      line-height: 1.3;
    }
  }
}
